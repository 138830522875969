body {
  background-color: black;
  color: white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background-color: transparent;
  position: absolute;
  width: calc(100% - 100px);
  top: 0;
  left: 0;
  z-index: 499;
  border-bottom: 1px solid white;
}

.logo {
  font-size: 30px;
  color: white;
}

.navBar {
  display: flex;
  gap: 20px;
}

.navBar button {
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
}

.navBar button:hover {
  color: rgb(11, 119, 214);
  text-decoration: underline;
  text-decoration-style: wavy;
}

.content-head {
  width: 100%;
  height: 500px;
  display: flex;
}

.contenhdone {
  color: white;
  width: calc(100% - 600px);
  margin-top: 70px;
  padding: 100px 50px;
}

.contenhdtwo {
  background: url("../../../public/yogesh-kumar.jpg");
  background-size: cover;
  height: 500px;
  width: 500px;
  margin-left: auto;
}

.contenhdtwo::before {
  content: "";
  position: absolute;
  height: 500px;
  width: 500px;
  background: linear-gradient(
    to right,
    black,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  );
}

.hireCont {
  padding: 50px;
  width: calc(100% - 100px);
  background: linear-gradient(
    to right,
    black,
    rgb(11, 119, 214),
    rgb(4, 36, 63),
    black
  );
}

#hi3re {
  margin-left: 400px;
  padding: 10px 40px;
  border: none;
  border-radius: 12px 0 12px 0;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.bottom-content {
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.lang-box img {
  width: 100px;
}

.lang-box {
  width: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  padding: 8px;
  border-radius: 8px;
}

.lang-exp h3 {
  display: flex;
  justify-content: center;
  background-color: rgb(11, 119, 214);
  background: linear-gradient(to right, black, rgb(11, 119, 214), black);
  padding: 5px 0;
}

.lang-row {
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}

.recent-posts {
  border: 1px solid rgb(11, 119, 214);
  margin-top: 50px;
  border-radius: 12px;
  background-color: white;
}

.my-posts {
  border: 1px solid rgb(11, 119, 214);
  margin: 50px;
  border-radius: 12px;
  background-color: white;
}

.post-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recent-head {
  background-color: white;
  color: black;
  padding: 20px;
  margin: 0;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  border-bottom: 1px solid #b4b4b4;
}

.post-button {
  background-color: rgb(11, 119, 214);
  padding: 5px 15px;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.post-tile {
  display: flex;
  padding: 15px;
  border: 1px solid #b4b4b4;
  color: black;
  gap: 15px;
  border-radius: 12px;
  margin: 15px;
  cursor: pointer;
}

.post-tile:hover {
  background-color: #f6f6f6;
  background: linear-gradient(to bottom, white, #f6f6f6);
}

.post-tile img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 12px;
}

.contact-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20%;
  background-color: rgb(11, 119, 214);
}

.contact-phone {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-phone img {
  width: 20px;
  height: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 50px auto;
}

textarea {
  resize: none;
  padding: 5px 8px;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 4px;
}

input {
  padding: 5px 8px;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 4px;
}

label,
.img-list-box span {
  font-size: 12px;
  margin: 8px 0 5px 0;
}

#contact-submit {
  margin-top: 20px;
  padding: 5px 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: rgb(11, 119, 214);
  border: none;
  color: white;
  border-radius: 4px;
}

.contact-head h5 {
  font-size: 20px;
}

footer {
  display: flex;
  justify-content: center;
  font-size: 10px;
  padding: 20px;
  border-top: 1px dotted white;
}

.load-more {
  margin: 20px 0;
  padding: 5px 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: rgb(11, 119, 214);
  border: none;
  color: white;
  border-radius: 4px;
}

.writing-box {
  display: flex;
}

.img-list-box {
  display: flex;
  flex-direction: column;
  margin: 30px 50px;
}

.img-box {
  width: 150px;
  height: 150px;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px dashed black;
  font-size: 50px;
  color: rgb(11, 119, 214);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: relative;
}

.img-box-hor {
  width: 150px;
  height: 100px;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px dashed black;
  font-size: 50px;
  color: rgb(11, 119, 214);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: relative;
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 50px 30px 0;
  color: black;
}

.img-list-box span {
  color: black;
}

#post-submit {
  margin: 20px 0;
  padding: 5px 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: rgb(11, 119, 214);
  border: none;
  color: white;
  border-radius: 4px;
}

.img-box-head-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rem-img {
  padding: none;
  border: none;
  background-color: white;
  font-size: 10px;
  border-bottom: 1px solid red;
  border-radius: 12px;
  cursor: pointer;
  color: red;
}
