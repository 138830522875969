.toast {
  position: fixed;
  top: 20px;
  right: -100%;
  background-color: rgb(255, 226, 171);
  color: #333333;
  padding: 10px 20px;
  border: 1px solid rgb(255, 166, 0);
  border-radius: 12px 0 12px 12px;
  transition: right 0.5s ease-in-out;
  z-index: 500;
}

.toast.show {
  right: 20px;
}

.toast-message {
  font-size: 14px;
  font-weight: 600;
}

.lo4ade3r-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 299;
}

.lo44ade3r {
  --w: 10ch;
  font-weight: 600;
  font-size: 25px;
  line-height: 1.4em;
  letter-spacing: var(--w);
  width: var(--w);
  z-index: 300;
  overflow: hidden;
  white-space: nowrap;
  color: #0084ff;
  text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
    calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
    calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
    calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
    calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  animation: l20 2s infinite linear;
}

.lo44ade3r:before {
  content: "Loading...";
}

@keyframes l20 {
  9.09% {
    text-shadow: calc(0 * var(--w)) -10px #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  18.18% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) -10px #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  27.27% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) -10px #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  36.36% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) -10px #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  45.45% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) -10px #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  54.54% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) -10px #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  63.63% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) -10px #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  72.72% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) -10px #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  81.81% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) -10px #0084ff, calc(-9 * var(--w)) 0 #0084ff;
  }

  90.90% {
    text-shadow: calc(0 * var(--w)) 0 #0084ff, calc(-1 * var(--w)) 0 #0084ff,
      calc(-2 * var(--w)) 0 #0084ff, calc(-3 * var(--w)) 0 #0084ff,
      calc(-4 * var(--w)) 0 #0084ff, calc(-5 * var(--w)) 0 #0084ff,
      calc(-6 * var(--w)) 0 #0084ff, calc(-7 * var(--w)) 0 #0084ff,
      calc(-8 * var(--w)) 0 #0084ff, calc(-9 * var(--w)) -10px #0084ff;
  }
}
